export class Video {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('[data-video-play]').on('click', (event) => {
            const play = $(event.currentTarget);
            const parent = play.closest('.video__wrapper');
            const placeholder = parent.find('.video__placeholder')
            const video = parent.find('video');

            play.addClass('video__play--hide');
            placeholder.addClass('video__placeholder--hide');
            video[0].play();
        });
    }
}

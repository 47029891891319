import Swiper from 'swiper';

export class Slider {
    constructor(selector){
        if($(selector).length){
            this.init(selector);
        }
    }

    init(selector) {
        const sliders = $(selector).find('.swiper-container');
        sliders.each((index) => {
            const slider = sliders.eq(index);
            const counter = slider.find('.slider__counts-text--current');
            let options = {
                slidesPerView: 1,
                speed: 1000,
                loop: true,
                navigation: {
                    nextEl: '.slider__arrow--next',
                    prevEl: '.slider__arrow--prev',
                },
                on: {
                    slideChangeTransitionStart: (e) => {
                        const index = slider.find('.swiper-slide-active').data('swiper-slide-index');
                        let real_index = index + 1;
                        if(real_index < 10){
                            real_index = `0${real_index}`;
                        }

                        counter.html(real_index);
                    }
                },
            };

            if (typeof slider.data('slider-delay') !== 'undefined') {
                options.autoplay = {
                    delay: slider.data('slider-delay')
                };
            }

            // autoplay: {
            //     delay: slider.data('slider-delay')
            // },

            new Swiper(slider, options);
        });
    }
}

export class Submenu {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('[data-submenu-id]').on('click', (event) => {
            const submenu_id = $(event.currentTarget).data('submenu-id');

            $('.submenu__item').removeClass('submenu__item--active');
            $('.submenu__container').removeClass('submenu__container--active');

            $(event.currentTarget).addClass('submenu__item--active');
            $(`[data-submenu-container-id="${submenu_id}"]`).addClass('submenu__container--active');
        });
    }
}

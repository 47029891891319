export class Scroll {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('[data-scroll-top]').on('click', () => {
            this.scrollTo(0);
        });

        $('[data-scroll-to]').on('click', (event) => {
            const offset = this.getOffset(event);
            this.scrollTo(offset);
        });
    }

    getOffset(event) {
        const selector = $(event.currentTarget).data('scroll-to');
        return $(selector).offset().top;
    }

    scrollTo(offset) {
        $('html, body').stop().animate({ scrollTop: offset }, 500, 'swing');
    }
}

// styles
import '../scss/style.scss';

// components
import { Slider } from './components/slider';
import { Scroll } from './components/scroll';
import { Duk } from './components/duk';
import { Submenu } from './components/Submenu';
import { Map } from './components/map';
import { Dots } from './components/dots';
import { Video } from './components/video';
import { Menu } from './components/menu';
import { Cookies } from './components/cookies';
import { Header } from './components/header';

(() => {
    new Slider('[data-slider]');
    new Scroll('[data-scroll]');
    new Duk('[data-duk]');
    new Submenu('[data-submenu]');
    new Map('#map');
    new Dots('[data-dots]');
    new Video('[data-video]');
    new Menu('[data-menu]');
    new Cookies('[data-cookies]');
    new Header('[data-header]');
})();

export class Menu {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('.header__burger').on('click', (event) => {
            $(event.currentTarget).toggleClass('header__burger--active');
            $(selector).stop(true, true).slideToggle(300);
            $('html, body').toggleClass('stop-scrolling');
        });
    }
}

export class Map {
    constructor(selector) {
        if ($(selector).length) {
            this.appendScript(selector);
        }
    }

    appendScript(selector) {
        const key = $(selector).data('key');

        $('<script/>',{
            type: 'text/javascript',
            src: `https://maps.googleapis.com/maps/api/js?key=${key}&callback=init`
        }).appendTo('body');
    }
}

window.init = () => {
    const map = new google.maps.Map($('#map')[0], {
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        zoomControl: true,
        clickableIcons: false,
        center: {
            lat: $('#map').data('lat'),
            lng: $('#map').data('lng')
        },
        styles: [
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#d3d3d3"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "color": "#808080"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#b3b3b3"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "weight": 1.8
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#d7d7d7"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ebebeb"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#a7a7a7"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#efefef"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#696969"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#737373"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#d6d6d6"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {},
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            }
        ],
        zoom: 10,
    });

    const infowindows = [];
    const markers = [];
    pins.forEach((pin) => {
        const marker = new google.maps.Marker({
            map: map,
            draggable: false,
            position: new google.maps.LatLng(pin.lat, pin.lng),
            icon: {
                url:'data:image/svg+xml;charset=utf-8,' + encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" width="38.188" height="55" viewBox="0 0 38.188 55"><path fill="#da251c" d="M846.5,487.645a18.978,18.978,0,0,0-27,0c-6.673,6.73-7.5,19.394-1.8,27.067L833,537l15.274-22.252C854,507.039,853.175,494.375,846.5,487.645Zm-13.325,20.47a7.03,7.03,0,1,1,6.969-7.03A7.009,7.009,0,0,1,833.176,508.115Z" transform="translate(-813.906 -482)"/></svg>'),
                scaledSize: new google.maps.Size(38, 55)
            },
        });

        markers.push(marker);

        if(pin.infowindow){
            const infowindow = new google.maps.InfoWindow({
                content: `<div class="map-window"><img class="map-window__image" src="${pin.src}"/><div class="map-window__text-wrap"><span class="map-window__title">${pin.title}</span><span class="map-window__text">${pin.text}</span></div></div>`
            });

            infowindows.push(infowindow);

            marker.addListener('click', function() {
                infowindows.forEach((window) => {
                    window.close();
                });
                infowindow.open(map, marker);
            });

            map.addListener('click', function() {
                infowindow.close();
            });
        }
    });

    const rootUrl = $('body').data('root-url');
    const imagePath = `${rootUrl}/themes/stimelit/assets/images/m`;
    const markerClusterer = new MarkerClusterer(map, markers, {imagePath: imagePath});
}

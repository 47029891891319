import axios from 'axios';

export class Cookies {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('[data-close-cookies]').on('click', (event) => {
            $('.cookies').addClass('cookies--hide');
            setTimeout(function(){
                $('.cookies').remove();
            }, 400);
        });

        $('[data-accept-cookies]').on('click', (event) => {
            $('.cookies').addClass('cookies--hide');
            setTimeout(function(){
                $('.cookies').remove();
            }, 400);

            axios({
                method: 'post',
                url: '/endpoints/cookies',
            });
        });
    }
}

export class Dots {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('.dots__dot').mouseenter((event) => {
            const current_dot = $(event.currentTarget);
            const parent = current_dot.closest('.dots');
            const dot_matrix = current_dot.data('dots-matrix');
            current_dot.addClass('dots__dot--center');

            parent.find(`[data-dots-matrix="[${dot_matrix[0]},${dot_matrix[1] - 1}]"]`).addClass('dots__dot--around');
            parent.find(`[data-dots-matrix="[${dot_matrix[0]},${dot_matrix[1] + 1}]"]`).addClass('dots__dot--around');
            parent.find(`[data-dots-matrix="[${dot_matrix[0] - 1},${dot_matrix[1]}]"]`).addClass('dots__dot--around');
            parent.find(`[data-dots-matrix="[${dot_matrix[0] + 1},${dot_matrix[1]}]"]`).addClass('dots__dot--around');

            parent.find(`[data-dots-matrix="[${dot_matrix[0] - 1},${dot_matrix[1] - 1}]"]`).addClass('dots__dot--around-small');
            parent.find(`[data-dots-matrix="[${dot_matrix[0] + 1},${dot_matrix[1] + 1}]"]`).addClass('dots__dot--around-small');
            parent.find(`[data-dots-matrix="[${dot_matrix[0] - 1},${dot_matrix[1] + 1}]"]`).addClass('dots__dot--around-small');
            parent.find(`[data-dots-matrix="[${dot_matrix[0] + 1},${dot_matrix[1] - 1}]"]`).addClass('dots__dot--around-small');
        })
        .mouseleave(() => {
            $('.dots__dot').removeClass('dots__dot--center');
            $('.dots__dot').removeClass('dots__dot--around');
            $('.dots__dot').removeClass('dots__dot--around-small');
        });
    }
}

export class Header {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {

        $(window).on({
            mouseenter: (event) => {
                if($('[data-menu-id]').is(event.target) || $('[data-menu-id]').has(event.target).length > 0){
                    const element = $(event.target).closest('[data-menu-id]');
                    const menu_id = element.data('menu-id');
                    if($('.header__desktop-submenu-wrap--show-submenu').length){
                        const active_menu_id = $('.header__desktop-submenu-wrap--show-submenu').data('submenu-id');
                        console.log(active_menu_id);
                        if(active_menu_id != menu_id){
                            $('[data-submenu-id]').removeClass('header__desktop-submenu-wrap--show-submenu');
                            $('[data-menu-id]').removeClass('header__menu-item--show-submenu');
                        }
                    }

                    $(`[data-submenu-id="${menu_id}"]`).addClass('header__desktop-submenu-wrap--show-submenu');
                    element.addClass('header__menu-item--show-submenu');
                }
                else{
                    if(!$('.header__desktop-submenu-wrap').is(event.target) && $('.header__desktop-submenu-wrap').has(event.target).length === 0){
                        $('[data-submenu-id]').removeClass('header__desktop-submenu-wrap--show-submenu');
                        $('[data-menu-id]').removeClass('header__menu-item--show-submenu');
                    }
                }
            }
        });

        $('.header__desktop-submenu-wrap').on({
            mouseleave: (event) => {
                $('[data-submenu-id]').removeClass('header__desktop-submenu-wrap--show-submenu');
                $('[data-menu-id]').removeClass('header__menu-item--show-submenu');
            }
        });
    }
}

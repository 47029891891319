export class Duk {
    constructor(selector) {
        if ($(selector).length) {
            this.init(selector);
        }
    }

    init(selector) {
        $('[data-duk-head]').on('click', (event) => {
            const parent = $(event.currentTarget).parent();
            const body = parent.find('[data-duk-body]');

            parent.toggleClass('duk__single--active');
            body.stop(true, true).slideToggle();
        });
    }
}
